.full_container {
    padding: 25px 15px 50px;
    display: block;
    background: $colorBackgroundAlternate;
    
    .profile-container {
        text-align: left;
        max-width: 50%;
        margin: auto;
    }

    ul {
        list-style-type: disc;
        margin-left: 30px;

        li {
            margin-bottom: 15px;
            
        }
    }
}